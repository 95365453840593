/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader />

        <Column className="css-1ijhuvf --style2 --full --parallax" anim={""} name={"uvod"} animS={"3"} border={""} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390,"backgroundColor":"rgba(71,36,245,1)"}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Fotogalerie"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/0309ac10eea847d6a0e7486871672f97_e=0x0x2048x1542_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32042/0309ac10eea847d6a0e7486871672f97_e=0x0x2048x1542_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/0309ac10eea847d6a0e7486871672f97_e=0x0x2048x1542_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/0309ac10eea847d6a0e7486871672f97_e=0x0x2048x1542_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/0309ac10eea847d6a0e7486871672f97_e=0x0x2048x1542_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/0309ac10eea847d6a0e7486871672f97_e=0x0x2048x1542_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/8db4f56f8f3344d8afe089ed8e90dfd1_e=0x16x2048x1526_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32042/8db4f56f8f3344d8afe089ed8e90dfd1_e=0x16x2048x1526_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/8db4f56f8f3344d8afe089ed8e90dfd1_e=0x16x2048x1526_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/8db4f56f8f3344d8afe089ed8e90dfd1_e=0x16x2048x1526_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/8db4f56f8f3344d8afe089ed8e90dfd1_e=0x16x2048x1526_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/8db4f56f8f3344d8afe089ed8e90dfd1_e=0x16x2048x1526_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/90e80a3f04554627a06d7c28fbf27810_e=0x16x2048x1526_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32042/90e80a3f04554627a06d7c28fbf27810_e=0x16x2048x1526_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/90e80a3f04554627a06d7c28fbf27810_e=0x16x2048x1526_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/90e80a3f04554627a06d7c28fbf27810_e=0x16x2048x1526_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/90e80a3f04554627a06d7c28fbf27810_e=0x16x2048x1526_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/90e80a3f04554627a06d7c28fbf27810_e=0x16x2048x1526_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/682a27df014d455bbb01cd463769e416_e=9x13x2039x1529_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32042/682a27df014d455bbb01cd463769e416_e=9x13x2039x1529_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/682a27df014d455bbb01cd463769e416_e=9x13x2039x1529_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/682a27df014d455bbb01cd463769e416_e=9x13x2039x1529_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/682a27df014d455bbb01cd463769e416_e=9x13x2039x1529_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/682a27df014d455bbb01cd463769e416_e=9x13x2039x1529_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/de30f0ba42314c02b9fb2a7f2a0a4441_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/de30f0ba42314c02b9fb2a7f2a0a4441_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/de30f0ba42314c02b9fb2a7f2a0a4441_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/de30f0ba42314c02b9fb2a7f2a0a4441_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/de30f0ba42314c02b9fb2a7f2a0a4441_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/de30f0ba42314c02b9fb2a7f2a0a4441_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/a1baf7a9cfe94fe8b3f04f2a0bc9bdcb_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/a1baf7a9cfe94fe8b3f04f2a0bc9bdcb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/a1baf7a9cfe94fe8b3f04f2a0bc9bdcb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/a1baf7a9cfe94fe8b3f04f2a0bc9bdcb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/a1baf7a9cfe94fe8b3f04f2a0bc9bdcb_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/a1baf7a9cfe94fe8b3f04f2a0bc9bdcb_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/e334d4cd174f4ee2a30b1e2f69825bb2_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/e334d4cd174f4ee2a30b1e2f69825bb2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/e334d4cd174f4ee2a30b1e2f69825bb2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/e334d4cd174f4ee2a30b1e2f69825bb2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/e334d4cd174f4ee2a30b1e2f69825bb2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/e334d4cd174f4ee2a30b1e2f69825bb2_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/5fc613083ffb45cbacef63a34df31487_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/5fc613083ffb45cbacef63a34df31487_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/5fc613083ffb45cbacef63a34df31487_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/5fc613083ffb45cbacef63a34df31487_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/5fc613083ffb45cbacef63a34df31487_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/5fc613083ffb45cbacef63a34df31487_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/727a5cc05d7b417d8d1cb2431c4ac556_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/727a5cc05d7b417d8d1cb2431c4ac556_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/727a5cc05d7b417d8d1cb2431c4ac556_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/727a5cc05d7b417d8d1cb2431c4ac556_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/727a5cc05d7b417d8d1cb2431c4ac556_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/727a5cc05d7b417d8d1cb2431c4ac556_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/0753d2472e8446deb6197c7580530ab6_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/0753d2472e8446deb6197c7580530ab6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/0753d2472e8446deb6197c7580530ab6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/0753d2472e8446deb6197c7580530ab6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/0753d2472e8446deb6197c7580530ab6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/0753d2472e8446deb6197c7580530ab6_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/3c210d84018748eca30253a829394cc7_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/3c210d84018748eca30253a829394cc7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/3c210d84018748eca30253a829394cc7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/3c210d84018748eca30253a829394cc7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/3c210d84018748eca30253a829394cc7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/3c210d84018748eca30253a829394cc7_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/14d6442a0352478497565f4219095b70_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/14d6442a0352478497565f4219095b70_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/14d6442a0352478497565f4219095b70_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/14d6442a0352478497565f4219095b70_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/14d6442a0352478497565f4219095b70_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/14d6442a0352478497565f4219095b70_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/ec68e75bf81845229345b9b84821a628_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/ec68e75bf81845229345b9b84821a628_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/ec68e75bf81845229345b9b84821a628_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/ec68e75bf81845229345b9b84821a628_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/ec68e75bf81845229345b9b84821a628_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/ec68e75bf81845229345b9b84821a628_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/1fbc68ab4f1e433caa8d9721f7273c42_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/1fbc68ab4f1e433caa8d9721f7273c42_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/1fbc68ab4f1e433caa8d9721f7273c42_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/1fbc68ab4f1e433caa8d9721f7273c42_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/1fbc68ab4f1e433caa8d9721f7273c42_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/1fbc68ab4f1e433caa8d9721f7273c42_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/8d7ac31e122e4001b454c3d18191376b_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/8d7ac31e122e4001b454c3d18191376b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/8d7ac31e122e4001b454c3d18191376b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/8d7ac31e122e4001b454c3d18191376b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/8d7ac31e122e4001b454c3d18191376b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/8d7ac31e122e4001b454c3d18191376b_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/b3eb4f8eed564dc3be68780fa12bb071_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/b3eb4f8eed564dc3be68780fa12bb071_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/b3eb4f8eed564dc3be68780fa12bb071_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/b3eb4f8eed564dc3be68780fa12bb071_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/b3eb4f8eed564dc3be68780fa12bb071_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/b3eb4f8eed564dc3be68780fa12bb071_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/fa6c5c0d599244f19342f837ecbc881a_e=0x2x2048x1535_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32042/fa6c5c0d599244f19342f837ecbc881a_e=0x2x2048x1535_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/fa6c5c0d599244f19342f837ecbc881a_e=0x2x2048x1535_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/fa6c5c0d599244f19342f837ecbc881a_e=0x2x2048x1535_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/fa6c5c0d599244f19342f837ecbc881a_e=0x2x2048x1535_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/fa6c5c0d599244f19342f837ecbc881a_e=0x2x2048x1535_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/14e1614dd4f24d2ca508c2dfefad00bb_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/14e1614dd4f24d2ca508c2dfefad00bb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/14e1614dd4f24d2ca508c2dfefad00bb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/14e1614dd4f24d2ca508c2dfefad00bb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/14e1614dd4f24d2ca508c2dfefad00bb_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/14e1614dd4f24d2ca508c2dfefad00bb_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/bda05aa47c494a93be72059077dc95f4_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/bda05aa47c494a93be72059077dc95f4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/bda05aa47c494a93be72059077dc95f4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/bda05aa47c494a93be72059077dc95f4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/bda05aa47c494a93be72059077dc95f4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/bda05aa47c494a93be72059077dc95f4_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/6f184892b28a4966808442adb7c7359c_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/6f184892b28a4966808442adb7c7359c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/6f184892b28a4966808442adb7c7359c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/6f184892b28a4966808442adb7c7359c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/6f184892b28a4966808442adb7c7359c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/6f184892b28a4966808442adb7c7359c_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/03a2e660d35247f4b1736ca5c59b0687_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/03a2e660d35247f4b1736ca5c59b0687_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/03a2e660d35247f4b1736ca5c59b0687_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/03a2e660d35247f4b1736ca5c59b0687_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/03a2e660d35247f4b1736ca5c59b0687_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/d1096bc44f34418c8c407a6926065d07_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/d1096bc44f34418c8c407a6926065d07_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/d1096bc44f34418c8c407a6926065d07_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/d1096bc44f34418c8c407a6926065d07_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/d1096bc44f34418c8c407a6926065d07_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/d1096bc44f34418c8c407a6926065d07_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/be265c346c4c43e08352e0a9495f7f58_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/be265c346c4c43e08352e0a9495f7f58_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/be265c346c4c43e08352e0a9495f7f58_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/be265c346c4c43e08352e0a9495f7f58_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/be265c346c4c43e08352e0a9495f7f58_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/be265c346c4c43e08352e0a9495f7f58_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/5c92944fd7594fa6bd18ff7200de6337_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/5c92944fd7594fa6bd18ff7200de6337_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/5c92944fd7594fa6bd18ff7200de6337_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/5c92944fd7594fa6bd18ff7200de6337_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/5c92944fd7594fa6bd18ff7200de6337_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/5c92944fd7594fa6bd18ff7200de6337_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/8a3b444c019240d1837bfedb32c841fe_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/8a3b444c019240d1837bfedb32c841fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/8a3b444c019240d1837bfedb32c841fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/8a3b444c019240d1837bfedb32c841fe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/8a3b444c019240d1837bfedb32c841fe_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/d301878ce0b243e486c3d7c507f38c91_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/d301878ce0b243e486c3d7c507f38c91_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/d301878ce0b243e486c3d7c507f38c91_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/d301878ce0b243e486c3d7c507f38c91_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/d301878ce0b243e486c3d7c507f38c91_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/d301878ce0b243e486c3d7c507f38c91_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/31ee0db098974bfdba0cdeb5d530a9fc_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/31ee0db098974bfdba0cdeb5d530a9fc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/31ee0db098974bfdba0cdeb5d530a9fc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/31ee0db098974bfdba0cdeb5d530a9fc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/31ee0db098974bfdba0cdeb5d530a9fc_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/31ee0db098974bfdba0cdeb5d530a9fc_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/40031aca8a3f4046a66bc4ff25a0eb7a_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/40031aca8a3f4046a66bc4ff25a0eb7a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/40031aca8a3f4046a66bc4ff25a0eb7a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/40031aca8a3f4046a66bc4ff25a0eb7a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/40031aca8a3f4046a66bc4ff25a0eb7a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/40031aca8a3f4046a66bc4ff25a0eb7a_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/01198b0c6e5f4986b474243954016031_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/01198b0c6e5f4986b474243954016031_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/01198b0c6e5f4986b474243954016031_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/01198b0c6e5f4986b474243954016031_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/01198b0c6e5f4986b474243954016031_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/f4289d82751a49859cd6440dd0d17667_e=0x19x2033x1523_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32042/f4289d82751a49859cd6440dd0d17667_e=0x19x2033x1523_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/f4289d82751a49859cd6440dd0d17667_e=0x19x2033x1523_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/f4289d82751a49859cd6440dd0d17667_e=0x19x2033x1523_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/f4289d82751a49859cd6440dd0d17667_e=0x19x2033x1523_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/f4289d82751a49859cd6440dd0d17667_e=0x19x2033x1523_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/604ccce7db6e492d913b2f7a60c5f4e8_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/604ccce7db6e492d913b2f7a60c5f4e8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/604ccce7db6e492d913b2f7a60c5f4e8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/604ccce7db6e492d913b2f7a60c5f4e8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/604ccce7db6e492d913b2f7a60c5f4e8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/604ccce7db6e492d913b2f7a60c5f4e8_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/7074073178d14c0c8e8c73251fe80586_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/7074073178d14c0c8e8c73251fe80586_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/7074073178d14c0c8e8c73251fe80586_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/7074073178d14c0c8e8c73251fe80586_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/7074073178d14c0c8e8c73251fe80586_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/7074073178d14c0c8e8c73251fe80586_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/ebcf44b5361243bcb353bcdc37070b5a_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/ebcf44b5361243bcb353bcdc37070b5a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/ebcf44b5361243bcb353bcdc37070b5a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/ebcf44b5361243bcb353bcdc37070b5a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/ebcf44b5361243bcb353bcdc37070b5a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/ebcf44b5361243bcb353bcdc37070b5a_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/af178e65d73c413a83a87fd026c1ab76_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/af178e65d73c413a83a87fd026c1ab76_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/af178e65d73c413a83a87fd026c1ab76_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/af178e65d73c413a83a87fd026c1ab76_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/af178e65d73c413a83a87fd026c1ab76_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/af178e65d73c413a83a87fd026c1ab76_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/3470f6cb1d714ab0a583d4e2a050ef7d_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/3470f6cb1d714ab0a583d4e2a050ef7d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/3470f6cb1d714ab0a583d4e2a050ef7d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/3470f6cb1d714ab0a583d4e2a050ef7d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/3470f6cb1d714ab0a583d4e2a050ef7d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/3470f6cb1d714ab0a583d4e2a050ef7d_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/ca9edb1b2cd34d4c87a1b02bb0b2510c_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/ca9edb1b2cd34d4c87a1b02bb0b2510c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/ca9edb1b2cd34d4c87a1b02bb0b2510c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/ca9edb1b2cd34d4c87a1b02bb0b2510c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/ca9edb1b2cd34d4c87a1b02bb0b2510c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/ca9edb1b2cd34d4c87a1b02bb0b2510c_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/9f7f9fed65a444f3bbbb7c02585ea03f_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/9f7f9fed65a444f3bbbb7c02585ea03f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/9f7f9fed65a444f3bbbb7c02585ea03f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/9f7f9fed65a444f3bbbb7c02585ea03f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/9f7f9fed65a444f3bbbb7c02585ea03f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/9f7f9fed65a444f3bbbb7c02585ea03f_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/eafa527e487948c385c4d51ebc461449_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/eafa527e487948c385c4d51ebc461449_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/eafa527e487948c385c4d51ebc461449_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/eafa527e487948c385c4d51ebc461449_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/eafa527e487948c385c4d51ebc461449_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/eafa527e487948c385c4d51ebc461449_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/1b5df2f94f884b299a5ac4f544e6950a_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/1b5df2f94f884b299a5ac4f544e6950a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/1b5df2f94f884b299a5ac4f544e6950a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/1b5df2f94f884b299a5ac4f544e6950a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/1b5df2f94f884b299a5ac4f544e6950a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/1b5df2f94f884b299a5ac4f544e6950a_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/931b1f9daaf642d89b28313876abe555_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/931b1f9daaf642d89b28313876abe555_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/931b1f9daaf642d89b28313876abe555_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/931b1f9daaf642d89b28313876abe555_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/931b1f9daaf642d89b28313876abe555_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/931b1f9daaf642d89b28313876abe555_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/9476713d6b054ca589a99f1b079af47f_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/9476713d6b054ca589a99f1b079af47f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/9476713d6b054ca589a99f1b079af47f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/9476713d6b054ca589a99f1b079af47f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/9476713d6b054ca589a99f1b079af47f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/9476713d6b054ca589a99f1b079af47f_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/3b396e9303b54a3d9f8998ae33716da8_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/3b396e9303b54a3d9f8998ae33716da8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/3b396e9303b54a3d9f8998ae33716da8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/3b396e9303b54a3d9f8998ae33716da8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/3b396e9303b54a3d9f8998ae33716da8_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/dac582fb61944973b4f3bad42e44293f_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/dac582fb61944973b4f3bad42e44293f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/dac582fb61944973b4f3bad42e44293f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/dac582fb61944973b4f3bad42e44293f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/dac582fb61944973b4f3bad42e44293f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32042/dac582fb61944973b4f3bad42e44293f_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32042/cbdab41c88a9403aaa4dff0a8c6a9f02_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32042/cbdab41c88a9403aaa4dff0a8c6a9f02_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/cbdab41c88a9403aaa4dff0a8c6a9f02_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/cbdab41c88a9403aaa4dff0a8c6a9f02_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/cbdab41c88a9403aaa4dff0a8c6a9f02_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Modrý pavilon&nbsp;"}>
              </Title>

              <Text className="text-box" content={"+420 739 090 844<br>ocmodrypavilon@seznam.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}